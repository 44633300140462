<template>
  <form class="filters_form" @submit.prevent>
    <div class="filters_form__heading" v-if="typeof count === 'number'">
      Фильтр
    </div>
    <Select :options="categoryOptions" v-model="category" label="Категория" />
    <Select :options="markOptions" v-model="mark" label="Марка" />
    <div class="filters_form__price">
      <span>Цена</span>
      <div>
        <span>От</span>
        <NumberInput v-model="minPrice" />
        <span>До</span>
        <NumberInput v-model="maxPrice" />
      </div>
    </div>
    <div v-if="showDiscount" class="filters_form__discount">
      <input v-model="discount" id="discount" type="checkbox" />
      <label for="discount">
        <svg
          width="24"
          height="23"
          viewBox="0 0 24 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="23"
            height="22"
            rx="1.5"
            stroke="#1F0052"
          />
          <path
            v-show="discount"
            d="M7.5 11L11.25 16L17 7"
            stroke="#1F0052"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span> Дискаунт </span>
      </label>
    </div>
    <Select :options="sortByOptions" v-model="sortBy" />
    <div v-if="typeof count === 'number'" class="filters_form__count">
      {{ count }} авто
    </div>
  </form>
</template>

<script setup>
import NumberInput from "../forms/NumberInput.vue";
import Select from "../forms/Select.vue";
const props = defineProps({
  categoryOptions: {
    type: Array,
  },
  markOptions: {
    type: Array,
  },
  sortByOptions: {
    type: Array,
  },
  count: {
    type: Number,
  },
  showDiscount: {
    type: Boolean,
  }
});

const category = defineModel("category", { default: "Гибрид" });
const mark = defineModel("mark", { default: "EXEED" });
const sortBy = defineModel("sortBy", { default: "" });
const discount = defineModel("discount", { default: true });

const minPrice = defineModel("minPrice", { default: "" });
const maxPrice = defineModel("maxPrice", { default: "" });
</script>

<style lang="scss">
.filters_form {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 30px;
  color: #1f0052;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;

  .select {
    width: 187px;

    &:nth-child(2) {
      width: 240px;
    }

    &:last-child {
      width: 232px;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    & > span {
      margin-bottom: 5px;
    }

    .number_input__input {
      width: 100px;
    }
  }

  &__discount {
    height: 34.5px;
    display: flex;
    align-items: center;

    input {
      display: none;
    }

    label {
      display: flex;
      gap: 10px;
    }
  }
}
</style>
