<template>
  <div class="number_input form-border">
    <input v-model="inputValue" class="number_input__input" />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
const modelValue = defineModel({ default: "" });
const inputValue = ref(modelValue.value || "");

watch(
  () => inputValue.value,
  (value, oldValue) => {
    if (!Number(value) && String(value).length) {
      inputValue.value = oldValue || "";
    } else {
      modelValue.value = value;
    }
  }
);
</script>

<style lang="scss">
.number_input {
  &__input {
    height: 100%;
    padding: 6px 15px;
    font-family: "Exo 2";
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    background-color: transparent;

    &,
    &:focus {
      outline: none;
      border: none;
    }
  }
}
</style>
