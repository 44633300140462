<template>
  <button @blur="open= false" class="select">
    <div class="select__label">{{ label }}</div>
    <div class="select__selected form-border" @click="open = !open">
      <span>{{ modelValue }}</span>
      <svg
        :class="open && 'rotate'"
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L7 7L13 1"
          stroke="#1F0052"
          stroke-miterlimit="2.3662"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <div class="select__options" v-show="open">
      <div
        v-for="opt of filteredOpt"
        @click="
          modelValue = opt;
          open = false;
        "
      >
        {{ opt }}
      </div>
    </div>
  </button>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  label: {
    type: String,
  },
  options: {
    type: Array,
    default: ["варик", "варик2"],
  },
});
const modelValue = defineModel();

const open = ref(false);

const filteredOpt = computed(() =>
  props.options.filter((val) => val !== modelValue.value)
);
</script>

<style lang="scss">
.form-border {
  color: #2b0600;

  overflow: hidden;
  position: relative;
  border: 1px solid #1f0052;
  border-width: 0 1px 1px 0;

  &::before,
  &::after {
    z-index: -1;
    content: "";
    position: absolute;
    border: 1px solid #1f0052;
    background-color: #f9fdff;
  }

  &::before {
    left: 0;
    bottom: -5px;
    width: 10px;
    height: 100%;
    border-width: 1px 0 0 1px;
    transform: skewY(-45deg);
  }

  &::after {
    height: 100%;
    right: 0;
    top: 0;
    width: calc(100% - 10px);
    border-width: 1px 0 0 0;
  }
}
.select {
  user-select: none;
  position: relative;
  cursor: pointer;
  background: none;
  font: inherit;
  cursor: pointer;
  border: none;
  padding: 0;
  text-align: start;

  &__label {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: #1f0052;
    margin-bottom: 5px;
  }
  &__options {
    z-index: 1;
    width: 100%;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    background-color: #f9fdff;
    z-index: 1;

    & > div {
      padding: 6px 15px;
      border: 1px solid #1f0052;
      color: #2b0600;
    }
  }
  &__selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 10px 6px 15px;

    svg {
      transition: 0.5s ease;
      margin-top: 5px;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
