<template>
  <Teleport to="#cars-list">
    <div v-if="!!showFilters" class="cars_list__filters">
      <div class="cars_list__choice">
        <button
          :class="filters.choseFilters === 'Категория' && '--active'"
          @click="filters.choseFilters = 'Категория'"
        >
          Категория
        </button>
        <button
          :class="filters.choseFilters === 'Марка' && '--active'"
          @click="filters.choseFilters = 'Марка'"
        >
          Марка
        </button>
        <div class="vertical"></div>
      </div>
      <CategoryFilters
        v-if="filters.choseFilters === 'Категория'"
        :filters="categoriesArr"
        @select-filter="changeActiveClass"
      />
      <MarkFilters
        v-else
        :filters="marksArr"
        @select-filter="changeActiveClass"
      />
    </div>
    <div ref="carsList">
      <FiltersForm
        v-if="showFiltersForm"
        v-model:mark="filters.mark"
        v-model:discount="filters.isDiscount"
        v-model:sortBy="filters.sortBy"
        v-model:category="filters.category"
        v-model:minPrice="filters.minPrice"
        v-model:maxPrice="filters.maxPrice"
        :sortByOptions="sortByOptions"
        :category-options="categoriesOptions"
        :mark-options="marksOptions"
        :count="showCounter && filters.foundedCarsCount"
        :showDiscount="!hideDiscount"
      />
    </div>
    <div class="page-section cars_list__count" v-if="showCounter">
      По заданным критериям найдено {{ filters.foundedCarsCount }} авто
    </div>
    <div class="cars_list__list">
      <div>
        <template v-for="car of filteredCars" :key="car.title + car.mark">
          <CarCard
            v-bind="car"
            @openRent="$emit('openRent', $event)"
            @openBuy="$emit('openBuy', $event)"
          />
        </template>
      </div>
      <button
        v-show="filters.itemsCount < filters.foundedCarsCount"
        @click="showMore"
        class="btn_with_corner--secondary"
      >
        Показать ещё
      </button>
    </div>
  </Teleport>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import FiltersForm from "../components/carslist/FiltersForm.vue";
import CategoryFilters from "../components/carslist/CategoryFilters.vue";
import MarkFilters from "../components/carslist/MarkFilters.vue";
import CarCard from "../components/carslist/CarCard.vue";
const props = defineProps({
  cars: {
    type: String,
  },
});

const emits = defineEmits(["openRent", "openBuy", "changeCars"]);

const container = document.getElementById("cars-list");

const {
  filterCategory,
  itemsPerPage,
  allCategoryOption,
  allMarkOption,
  categories,
  marks,
  showFilters,
  sorts,
  sortDefault,
  showFiltersForm,
  showCounter,
  defaultDiscountOn,
  hideDiscount,
} = container.dataset;
const categoriesArr = categories && JSON.parse(categories);

const allCategory = allCategoryOption || "Любая";
const categoriesOptions = [
  allCategory,
  ...categoriesArr.map(({ title }) => title),
];

const marksArr = marks && JSON.parse(marks);
const allMark = allMarkOption || "Любая";
const marksOptions = [allMark, ...marksArr.map(({ title }) => title)];

const sortByOptions = sorts
  ? JSON.parse(sorts)
  : ["Сначала дешевые", "Сначала дорогие"];
const carsArr = filterCategory
  ? props.cars.filter((car) => {
      return car.category_resource_auto.includes(filterCategory);
    })
  : props.cars;

const carsPerPage = itemsPerPage ? +itemsPerPage : 9;

const filters = reactive({
  choseFilters: "Категория",
  category: categoriesOptions ? categoriesOptions[0] : "",
  mark: marksOptions ? marksOptions[0] : "",
  minPrice: "",
  maxPrice: "",
  isDiscount: !!defaultDiscountOn,
  sortBy: sortByOptions ? sortByOptions[sortDefault || 0] : "",
  itemsCount: carsPerPage,
  foundedCarsCount: 0,
});

const carsList = ref(null);

const changeActiveClass = (value) => {
  carsList.value?.scrollIntoView({ behavior: "smooth" });
  if (filters.choseFilters === "Категория") {
    filters.category = value;
    return;
  }
  filters.mark = value;
};

const filteredCars = computed(() => {
  let arr = [];

  if (showFiltersForm) {
    arr = carsArr
      .filter((car) => {
        return (
          (car.category.trim().includes(filters.category.trim()) ||
            filters.category === allCategory) &&
          (car.mark?.toLowerCase().trim() ===
            filters.mark?.toLowerCase().trim() ||
            filters.mark === allMark) &&
          (!filters.minPrice || +car.price >= +filters.minPrice) &&
          (!filters.maxPrice || +car.price <= +filters.maxPrice) &&
          (!filters.isDiscount || !!car.discount)
        );
      })
      .sort((a, b) =>
        filters.sortBy === sortByOptions[0]
          ? +a.price - +b.price
          : +b.price - +a.price
      );
    emits("changeCars", arr);
  } else {
    arr = carsArr;
  }

  filters.foundedCarsCount = arr.length;

  return arr.splice(0, filters.itemsCount);
});

const showMore = () => {
  filters.itemsCount += carsPerPage;
};
</script>

<style lang="scss">
.filters_form {
  margin-bottom: 30px;
}

#cars-list {
  padding-bottom: 60px;
}

.cars_list {
  &__count {
    font-family: "Exo 2";
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    color: #2b0600;
    padding-left: 0;
    margin-bottom: 30px;
  }

  &__list {
    gap: 40px;
    flex-direction: column;
    display: flex;
    align-items: center;

    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      max-width: 1170px;
    }
  }

  &__filters {
    margin-bottom: 70px;
    padding: 30px 0 70px;
    gap: 65px;
    flex-direction: column;
    display: flex;
    align-items: center;

    background-color: #000000;
  }

  &__choice {
    position: relative;

    > button {
      cursor: pointer;
      margin-bottom: 25px;

      position: relative;
      width: 120px;
      height: 20px;
      background: transparent;
      border: none;
      color: #0075ff;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;

      &:first-child {
        margin-right: 50px;
      }

      &::before {
        z-index: 1;
        position: absolute;
        display: block;
        content: "";
        width: 160px;
        height: 100%;
        background-color: #5c8bc1;
        transform: skewX(-45deg);
        left: -25px;
        bottom: -35px;
        border-radius: 0 5px 0 5px;
      }

      &.--active {
        color: #f9fdff;

        &::before {
          background-color: #0075ff;
        }
      }
    }
    .vertical {
      transform: translateX(-29%);
      position: absolute;
      width: 570px;
      height: 2px;
      background-color: #0075ff;

      &::before,
      &::after {
        top: -2px;
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        background-color: #0075ff;
        transform: rotate(45deg);
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
  }
}
</style>
